<template>
    <div class="content-head full-row" id="#">
        <div class="container">
            <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
                <div class="col-xl-7 col-md-12">
                    <div class="row">
                        <div class="col-lg-4 col-md-12"></div>

                        <div class="col-lg-6 col-md-12">
                            <div class="hover-classic-layer overlay-white hover-text-PushUpBottom mb-30">
                                <div class="post-image">
                                    <img src="@/assets/images/1649130101810.jpeg" style="border-radius: 20px;" alt="corporate template">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-5 col-md-12 mt-5" style="color:black;text-align:left;">
                    <h1 class="text-secondary mb-5" style="font-size: 70px;">Jordan Istiqlal Qalbi Adiba</h1>
                    <h4 class="text-secondary mb-5">Backend Developer or Fullstack Developer</h4>
                    <a href="https://www.linkedin.com/in/jordan-istiqlal-qalbi-adiba-85465b1b0/" target="_blank" class="pe-4"><i class="fab fa-linkedin-in fa-2x"></i></a>
                    <a href="https://github.com/Jordan-18" target="_blank" class="pe-4"><i class="fab fa-github fa-2x"></i></a>
                    <a href="https://twitter.com/IstiqlalJordan" target="_blank" class="pe-4 icon-freeze"><i class="fab fa-twitter fa-2x"></i></a>
                    <a href="https://www.facebook.com/jordan.istiqlalqalbi/" target="_blank" class="pe-4 icon-freeze"><i class="fab fa-facebook fa-2x"></i></a>
                    <a href="https://www.instagram.com/jordanistiqlal/" target="_blank" class="pe-4"><i class="fab fa-instagram fa-2x"></i></a>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
/* .full-row{
    margin-top: 75.962px;
} */
@media screen and (max-width: 768px) {

}
.icon-freeze{
    pointer-events: none;
    cursor: not-allowed;
}
</style>